<template>
<div style="text-align: center;padding: 15%">
  <div><img src="../assets/imgs/404.png" alt="" style="width: 30%;min-width: 300px"></div>
  <br>
  <div style="color: #94a3d2">你访问的页面跑丢了呢，亲~</div>
</div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>